import type { Session } from "@supabase/supabase-js";
import axios from "axios";
import type { ApiTaskName } from "./types";

const api_endpoint = 'https://compute.bunka.ai';

const TOPICS_ENDPOINT_PATH = `${api_endpoint}/topics/import/csv/`;
const TOPICS_REFRESH_PATH = `${api_endpoint}/topics/import/refresh/`;
const TOPICS_DELETE_PATH = `${api_endpoint}/topics`;

export interface postApiTaskType {
  task_id: string;
  dataset_id: number;
}

// API Request
export const postImportDataset = (taskName: ApiTaskName, data: FormData, session: Session | null) => {
  const apiURL = `${taskName === "topics" ? TOPICS_ENDPOINT_PATH : ""}`;
  return axios
    .post(apiURL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${session?.access_token}`,
      },
    })
    .then((res: { data: postApiTaskType }) => res.data);
};

export const postDeleteDataset = (taskName: ApiTaskName, datasetID: number, session: Session | null) => {
  const apiURL = `${taskName === "topics" ? TOPICS_DELETE_PATH : ""}/${datasetID}/delete`;
  return axios
    .post(
      apiURL,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session?.access_token}`,
        },
      },
    )
    .then((res: { data: postApiTaskType }) => res.data);
};

export const postRefreshDataset = (taskName: ApiTaskName, data: Record<string, string | number>, session: Session | null) => {
  const apiURL = `${taskName === "topics" ? TOPICS_REFRESH_PATH : ""}`;
  return axios
    .post(apiURL, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session?.access_token}`,
      },
    })
    .then((res: { data: postApiTaskType }) => res.data);
};

export const getampleDatasetSourceUrl = (datasetID: number, taskName = "topics") => `${api_endpoint}/${taskName}/${datasetID}/sample/`;

export const getSampleDatasetSource = async (id: number, session: Session | null, startLine = 0, endLine = 40) =>
  axios
    .post(
      getampleDatasetSourceUrl(id),
      {
        startLine,
        endLine,
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...(session?.access_token ? { Authorization: `Bearer ${session?.access_token}` } : {}),
        },
      },
    )
    .then((res: { data: Record<string, string>[] }) => res.data);
